<template>
  <div class="card border-primary">
    <h4 class="card-header bg-primary-light text-uppercase">Start signing up
      <template v-if="students.length > 0"> your {{ plural('student', students.length) }}</template>
      for {{ schoolYear(year) }}
    </h4>
    <div v-if="$wait.waiting('students.loading')" class="card-body">
      <cs-loading><h6>Fetching your students.</h6>
      </cs-loading>
    </div>
    <template v-else>
      <div class="list-group list-group-flush">
        <div class="list-group-item">
          <p class="lead pb-0">You can start to sign up for <strong v-html="program.name"/>, select
            {{ plural('student', students.length) }} that you want to start signing up with below. If you
            have
            other students you want to sign up you can add them after clicking the <strong>Start
              Signup</strong>
            button.</p>
          <div v-if="studentsWithCompletedInformationCards.length > 0">
            <div v-for="student in studentsWithCompletedInformationCards"
                 :key="student.id"
                 class="custom-control custom-checkbox">
              <input type="checkbox"
                     class="custom-control-input"
                     :id="`student_id_${student.id}`"
                     v-model="selectedStudents"
                     :value="student.id"
              >
              <label class="custom-control-label" :for="`student_id_${student.id}`">{{ student.name }}
                <small>({{
                    student.grade
                  }}/{{ student.school }})</small> </label>
            </div>
          </div>
        </div>
        <div v-if="studentsWithIncompleteInformationCards.length > 0"
             class="list-group-item list-group-item-info">
          <strong>{{ studentsWithIncompleteInformationCards.length > 1 ? 'These' : 'This' }}
            {{ plural('student', studentsWithIncompleteInformationCards.length) }}
            {{ studentsWithIncompleteInformationCards.length > 1 ? 'have an' : 'has an' }} incomplete
            Information {{ plural('Card', studentsWithIncompleteInformationCards.length) }}</strong>, if you
          select students with incomplete <strong>Information Cards</strong>> you will need to complete the
          <strong>Information Cards</strong> before you can {{ requireInformationCardCompletion ? 'start' : 'finish' }}
          signing up. {{ requireInformationCardCompletion ? '' : 'You can still start signing them up.' }}
          <div v-if="requireInformationCardCompletion" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 w-100">
            <div v-for="student in studentsWithIncompleteInformationCards"
                 class="col py-1"
                 :key="student.id">
              <cs-button type="info"
                         icon="information-card"
                         @click="addInformationCard(student)">
                Add information card for {{ student.name }} <small>({{
                  student.grade
                }}/{{ student.school }})</small>
              </cs-button>
            </div>
          </div>
          <template v-else>
            <div v-for="student in studentsWithIncompleteInformationCards"
                 :key="student.id"
                 class="custom-control custom-checkbox">
              <input type="checkbox"
                     class="custom-control-input"
                     :id="`student_id_${student.id}`"
                     v-model="selectedStudents"
                     :value="student.id"
              >
              <label class="custom-control-label" :for="`student_id_${student.id}`">{{ student.name }} <small>({{
                  student.grade
                }}/{{ student.school }})</small> </label>
            </div>
          </template>
        </div>

        <div v-if="studentsWithOutInformationCards.length > 0"
             class="list-group-item list-group-item-warning list-group-item-heading">
          You will be required to <strong>add an Information
          {{ plural('Card', studentsWithOutInformationCards.length) }}</strong> before enrolling the following
          {{ plural('student', studentsWithOutInformationCards.length) }}. <span
            v-if="!requireInformationCardCompletion">You can still start signing them
                    up, you just will need to complete the <strong>Information Card</strong> before paying and
                    finalizing their enrollments.</span>
          <div v-if="requireInformationCardCompletion" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 w-100">
            <div v-for="student in studentsWithOutInformationCards"
                 class="col py-1"
                 :key="student.id">
              <cs-button type="warning"
                         icon="information-card"
                         @click="addInformationCard(student)">
                Add information card for {{ student.name }} <small>({{
                  student.grade
                }}/{{ student.school }})</small>
              </cs-button>
            </div>
          </div>
          <template v-else>
            <div v-for="student in studentsWithOutInformationCards"
                 :key="student.id"
                 class="custom-control custom-checkbox">
              <input type="checkbox"
                     class="custom-control-input"
                     :id="`student_id_${student.id}`"
                     v-model="selectedStudents"
                     :value="student.id"
              >
              <label class="custom-control-label" :for="`student_id_${student.id}`">{{ student.name }} <small>({{
                  student.grade
                }}/{{ student.school }})</small></label>
            </div>
          </template>

        </div>
        <div v-if="ineligibleStudents.length > 0" class="list-group-item list-group-item-danger ">
          <p>The following {{ plural('student', students.length) }} are not eligible for {{ program.name }}, this
            may be because of their grade or school. If you think this is incorrect, you will need to update
            these students before signing them up.</p>
          <span v-html="ineligibleList"/>
          <cs-button type='success' icon="students" @click="editStudents">Update Students</cs-button>
        </div>
      </div>
    </template>
    <div v-if="!$wait.waiting('students.loading')" class="card-footer">
      <cs-button
          type="primary"
          icon="registration"
          :loading="$wait.waiting('registration.create')"
          @click="createRegistration"
      >Start Signing Up for {{ schoolYear(year) }}
      </cs-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getCurrentRegistrations, newRegistration } from '@/api/registrationApi'
import { getStudentsForProgram } from '@/api/studentAPI'
import { isEmpty } from '@/utilities/utilities'
import { plural, toList } from '@/utilities/stringFormatters'
import { schoolYear } from '@/utilities/dateFormatters'
import { UPDATE_REGISTRATION } from '@/constants/mutation-types'

export default {
  name: 'NewRegistration',
  props: {
    year: {
      type: Number,
      default: 0
    },
    initiallyExpanded: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      requireInformationCardCompletion: true,
      currentRegistrations: [],
      selectedStudents: [],
      students: [],
      eligibleStudents: [],
      ineligibleStudents: []
    }
  },
  created () {
    this.getStudents()
  },
  watch: {
    studentsWithCompletedInformationCards (students) {
      this.selectedStudents = students.map(student => {
        return student.id
      })
    }
  },
  computed: {
    ...mapGetters({
      currentYear: 'year',
      member: 'member',
      loggedIn: 'loggedIn',
      program: 'program'
    }),
    ineligibleList () {
      const students = []
      this.ineligibleStudents.forEach(student => {
        students.push(`${student.name} <small>(${student.grade}/${student.school})</small>`)
      })
      return toList(students)
    },
    studentsWithCompletedInformationCards () {
      return this.studentsWithInformationCards.filter(student => {
        return !this.incompleteInformationCard(student.information_cards[0])
      })
    },
    studentsWithIncompleteInformationCards () {
      return this.studentsWithInformationCards.filter(student => {
        return this.incompleteInformationCard(student.information_cards[0])
      })
    },
    studentsWithInformationCards () {
      return this.students.filter(student => {
        return !isEmpty(student.information_cards)
      })
    },
    studentsWithOutInformationCards () {
      return this.students.filter(student => {
        return isEmpty(student.information_cards)
      })
    }
  },
  methods: {
    ...mapActions({ getRegistration: UPDATE_REGISTRATION }),
    plural,
    schoolYear,
    addInformationCard (student) {
      const location = window.location.href
      const url = new URL(location)
      url.pathname = '/students/add'
      const keys = url.searchParams.keys()
      for (const key of keys) {
        url.searchParams.delete(key)
      }
      url.searchParams.set('member_id', this.member.id)
      url.searchParams.set('student_id', student.id)
      url.searchParams.set('program', this.program.slug)
      url.searchParams.set('year', this.year)
      url.searchParams.set('return_to', location)
      url.searchParams.set('next', 'return_to')
      window.location.href = url.href
    },
    completeInformationCard (student) {
      const informationCard = student.information_cards[0]
      const location = window.location.href
      const url = new URL(location)
      url.pathname = '/students/add'
      const keys = url.searchParams.keys()
      for (const key of keys) {
        url.searchParams.delete(key)
      }
      url.searchParams.set('id', informationCard.id)
      url.searchParams.set('return_to', location)
      url.searchParams.set('next', 'return_to')
      window.location.href = url.href
    },
    editStudents () {
      window.open('/profile#students', '_blank')
    },
    getStudents () {
      this.$wait.start('students.loading')
      getStudentsForProgram(this.program, this.member, true, this.year)
        .then(data => {
          this.$wait.end('students.loading')
          if (!isEmpty(data.eligible)) {
            data.eligible.forEach(student => {
              this.eligibleStudents.push(student)
            })
          }
          if (!isEmpty(data.ineligible)) {
            data.ineligible.forEach(student => {
              this.ineligibleStudents.push(student)
            })
          }
          this.setStudents()
        })
        .catch(e => {
          this.$wait.end('students.loading')
          console.error('NewRegistration.getStudents', e)
        })
    },
    setStudents () {
      const students = {}
      this.currentRegistrations.forEach(registration => {
        registration.students.forEach(student => {
          if (!(student.id in students) || !isEmpty(student.information_cards)) {
            students[student.id] = student
          }
        })
      })
      this.eligibleStudents.forEach(student => {
        if (!(student.id in students) || !isEmpty(student.information_cards)) {
          students[student.id] = student
        }
      })
      this.students = Object.values(students).sort((first, second) => {
        return first.first.localeCompare(second.first)
      })
    },
    incompleteInformationCard (card) {
      return isEmpty(card.completed)
    },
    studentHasCompleteInformationCard (student) {
      if (!isEmpty(student.information_cards)) {
        return !this.incompleteInformationCard(student.information_cards[0])
      }
      return false
    },
    createRegistration () {
      this.$wait.start('registration.create')
      newRegistration(this.year, this.program, this.selectedStudents)
        .then(result => {
          console.log('NewRegistration.createRegistration', result)
          this.$wait.end('registration.create')
          this.getRegistration({
            update: false,
            programSlug: this.program.slug
          })
            .then(result => {
              console.log('Registration.created', result)
            })
          this.$emit('new-registration', result.registration)
        })
        .catch(e => {
          console.error('NewRegistration.createRegistration', e)
          this.$wait.end('registration.create')
        })
    },
    /**
     * @deprecated
     */
    getStudentsFromCurrentRegistrations () {
      this.$wait.start('registrations.loading')
      getCurrentRegistrations(this.year)
        .then(result => {
          console.log('NewRegistration.created', result)
          this.currentRegistrations = result
          this.$wait.end('registrations.loading')
          if (this.currentRegistrations.length === 0) {
            this.$emit('no-current-registrations')
          } else {
            this.setStudents()
          }
        })
        .catch(e => {
          console.error('NewRegistration.created', e)
          this.$wait.end('registrations.loading')
        })
    }
  }
}
</script>

<style scoped>

</style>
