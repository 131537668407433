import { API } from '@/utilities/http-common'

const getShallowStudents = (studentIdArray) => {
  return API.get('/students/shallow', {
    params: {
      ids: studentIdArray
    }
  }).then(response => {
    if (response.status === 200) {
      const data = response.data
      return data.students
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

const getStudent = (id) => {
  return API.get('/students' + id)
    .then(response => {
      if (response.status === 200) {
        const data = response.data
        return data.student
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
}

const getStudentsForProgram = (program, member, withInformationCards = false, year = null) => {
  const params = {
    member_id: member.id
  }
  if (withInformationCards) {
    params.with_cards = withInformationCards
  }
  if (year !== null) {
    params.year = year
  }
  return API.get('programs/' + program.id + '/students/eligible.json', {
    params: params
  })
    .then(result => {
      if (result.status === 200) {
        return result.data
      } else {
        const error = new Error(result.statusText)
        error.code = result.status
        throw error
      }
    })
}

export { getShallowStudents, getStudent, getStudentsForProgram }
